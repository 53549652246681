:root {
    --ex-main-blue-primary: #0d91ff;
    --ex-main-blue-secondary: #28cbca;
    --ex-main-blue-tertiary: #002c47;
    --ex-complementary-blue-primary: #81cbfc;
    --ex-complementary-blue-secondary: #71e2df;
    --ex-complementary-blue-tertiary: #347799;
    --ex-complementary-black-primary: #353535;
    --ex-complementary-white-primary: #cecece;
    --ex-complementary-white-secondary: #efefef;
    --ex-contrast-orange: #E27B00;
    --ex-contrast-red: #CC3F33;
    --ex-contrast-yellow: #FFC703;
    --ex-contrast-green: #C5FD3E;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import "ex_app.css";

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  color: var(--ex-main-blue-primary);
}

.background-primary{
  background-color: var(--ex-main-blue-primary);
}
.background-secondary{
  background-color: var(--ex-main-blue-secondary);
}
.background-info{
  background-color: var(--ex-complementary-blue-tertiary);
}
.background-success{
  background-color: var(--ex-contrast-green);
}
.background-alert{
  background-color: var(--ex-contrast-red);
}

.btn-primary{
  background-color: var(--ex-complementary-blue-secondary);
  border: none;
  color: white;
}

.btn-secondary{
  background-color: var(--ex-complementary-blue-secondary);
  border: none;
  color: white;
}

.btn-space{
  margin-right: 10px;
}

button span{
  padding-left: 8px;
}

.highlight-icon {
  color: var(--ex-complementary-blue-secondary);
  padding: 0.5em;
}

.shorten-card {
  margin-top: 50px;
  background-color: var(--ex-main-blue-primary);
  padding: 42px;
  border-radius: 15px;
}

.modal-content {
  background-color: var(--ex-main-blue-primary);
}

.modal-header, .modal-footer{
  border: none;
}

.modal-body, .modal-title h4{
  color: white;
}

.toast{
  background-color: #0D91FF;
}

.toast-body h5{
  color: white;
}

.toast-body p{
  color: white;
}

.card-links-history{
  width: 100%;
  background-color: var(--ex-complementary-blue-secondary);
  border: none;
}

.card-link-text{
  font-size: 1em;
  font-weight: bold;
  margin: auto;
  margin-left: 0;
  color: var(--ex-complementary-white-secondary);
}

.heading{
  font-size: 1.2em;
}

.card-link-text a{
  text-decoration: none;
}

.card-link-text span{
  padding-left: 8px;
  padding-right: 8px;
  color: white;
}

.card-text {
  text-align: left;
  margin: auto;
  margin-left: 0;
  color: var(--ex-complementary-white-secondary);
  overflow: hidden;         /* Hide the overflow content */
  white-space: nowrap;      /* Prevent text wrapping */
  text-overflow: ellipsis;  /* Add ellipsis (...) for overflow */
}

.card-links-history > .card-body > button{
  background-color: white;
  color: #0D91FF;
}

.input-group button{
  font-size: 1.2em;
  padding: 8px;
}
@import "ex_app.css";

.App {
  text-align: center;
  background-color: var(--ex-complementary-white-secondary);
  height: 100%;
  min-height: 100vh;
 }

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-top: 5em;
}